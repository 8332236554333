interface ObjectObj {
  [propName: string]: string | number | null;
}
export default (obj: ObjectObj) => {
  for (const key in obj) {
    if (obj[key] === '') {
      obj[key] = null
    }
  }
  return obj
}