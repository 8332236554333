import Vue from 'vue'
import VueRouter, { RawLocation, RouteConfig } from 'vue-router'
//引入nprogress
import NProgress from 'nprogress'
import 'nprogress/nprogress.css' //这个样式必须引入
// import { format } from 'crypto-js'
// 简单配置
NProgress.inc(0.2)
NProgress.configure({ easing: 'ease', speed: 500, showSpinner: false })
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush.call(this, location) as any).catch((err: any) => err)
}
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/index.vue')
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home/index.vue')
      },
      {
        path: '/menu',
        meta: { title: '菜单管理(权限模块)' },
        name: 'menu',
        component: () => import('../views/permissionsManage/menuManage/index.vue')
      },
      {
        path: '/user',
        meta: { title: '用户管理(权限模块)' },
        name: 'user',
        component: () => import('../views/permissionsManage/userManage/index.vue')
      },
      {
        path: '/role',
        meta: { title: '角色管理(权限模块)' },
        name: 'role',
        component: () => import('../views/permissionsManage/roleManage/index.vue')
      },
      {
        path: '/institutionManage',
        meta: { title: '机构管理(体检预约相关)' },
        name: 'institutionManage',
        component: () => import('../views/medicalAppointment/institutionManage/index.vue')
      },
      {
        path: '/comboManage',
        meta: { title: '套餐管理(体检预约相关)' },
        name: 'comboManage',
        component: () => import('../views/medicalAppointment/comboManage/index.vue')
      },
      {
        path: '/packageType',
        meta: { title: '套餐分类' },
        name: 'packageType',
        component: () => import('../views/medicalAppointment/packageType/index.vue')
      },
      {
        path: '/orderQuery',
        meta: { title: '预约列表(体检预约相关)' },
        name: 'orderQuery',
        component: () => import('../views/medicalAppointment/orderQuery/index.vue')
      },
      {
        path: '/plan',
        meta: { title: '预约计划(体检预约相关)' },
        name: 'plan',
        component: () => import('../views/medicalAppointment/plan/index.vue')
      },
      {
        path: '/itemsManage',
        meta: { title: '项目管理(体检预约相关)' },
        name: 'itemsManage',
        component: () => import('../views/medicalAppointment/itemsManage/index.vue')
      },
      {
        path: '/evaluatingQuestionItem',
        meta: { title: '题目选项' },
        name: 'evaluatingQuestionItem',
        component: () => import('../views/checkBeforeEvaluating/evaluatingQuestionItem.vue')
      },
      {
        path: '/evaluatingQuestionCreate',
        meta: { title: '题目问卷' },
        name: 'evaluatingQuestionCreate',
        component: () => import('../views/checkBeforeEvaluating/evaluatingQuestionCreate.vue')
      },
      {
        path: '/evaluatingQuestion',
        meta: { title: '评估题目' },
        name: 'evaluatingQuestion',
        component: () => import('../views/checkBeforeEvaluating/evaluatingQuestion.vue')
      },
      {
        path: '/evaluatingRecord',
        meta: { title: '评估记录' },
        name: 'evaluatingRecord',
        component: () => import('../views/checkBeforeEvaluating/evaluatingRecord.vue')
      },
      {
        path: '/evaluatingStandard',
        meta: { title: '评估标准' },
        name: 'evaluatingStandard',
        component: () => import('../views/checkBeforeEvaluating/evaluatingStandard.vue')
      },
      {
        path: '/discountManagement',
        meta: { title: '项目营销' },
        name: 'discountManagement',
        component: () => import('../views/projectMarketing/discountManagement.vue')
      },
      {
        path: '/investigationRecords',
        meta: { title: '满意度调查' },
        name: 'investigationRecords',
        component: () => import('../views/satisfaction/investigationRecords.vue')
      },
      {
        path: '/dynamicState',
        meta: { title: '满意度问卷详情' },
        name: 'dynamicState',
        component: () => import('../views/satisfaction/dynamicState.vue')
      },
      {
        path: '/satisfactionStatistics',
        meta: { title: '统计分析' },
        name: 'satisfactionStatistics',
        component: () => import('../views/satisfaction/satisfactionStatistics.vue')
      },
      {
        path: '/satisfactionSetting',
        meta: { title: '满意度配置' },
        name: 'satisfactionSetting',
        component: () => import('../views/satisfaction/satisfactionSetting.vue')
      },
      {
        path: '/detectionLog',
        meta: { title: '检测日志' },
        name: 'detectionLog',
        component: () => import('../views/equipment/detectionLog.vue')
      },
      {
        path: '/statistical',
        meta: { title: '统计分析' },
        name: 'statistical',
        component: () => import('../views/equipment/statistical.vue')
      },
      {
        path: '/medicalReport',
        meta: { title: '报告查询' },
        name: 'medicalReport',
        component: () => import('../views/medicalReport/medicalReport.vue')
      },
      {
        path: '/healthyList',
        name: 'healthyList',
        meta: { title: '测评记录' },
        component: () => import('../views/healthyAppraisal/healthyList.vue')
      },
      {
        path: '/traditionalMedicine',
        name: 'traditionalMedicine',
        meta: { title: '中医体质问卷' },
        component: () => import('../views/traditional/traditionalMedicine.vue')
      },
      {
        path: '/schemeManage',
        name: 'schemeManage',
        meta: { title: '方案管理' },
        component: () => import('../views/healthyScheme/schemeManage.vue')
      },
      {
        path: '/payConfiguration',
        name: 'payConfiguration',
        meta: { title: '支付配置' },
        component: () => import('../views/payConfiguration/payConfiguration.vue')
      },
      {
        path: '/news',
        name: 'news',
        meta: { title: '疾病宝典' },
        component: () => import('../views/news/news.vue')
      },
      {
        path: '/newsType',
        name: 'newsType',
        meta: { title: '文章分类' },
        component: () => import('../views/news/newsType.vue')
      },
      {
        path: '/CustomerService',
        name: 'CustomerService',
        meta: { title: '客服' },
        component: () => import('../views/CustomerService/index.vue')
      },
      {
        path: '/professional',
        name: 'professional',
        meta: { title: '职业史' },
        component: () => import('../views/professional/index.vue')
      },
      {
        path: '/type1',
        name: 'type1',
        meta: { title: '危害因素' },
        component: () => import('../views/professional/type1.vue')
      },
      {
        path: '/type2',
        name: 'type2',
        meta: { title: '疾病列表' },
        component: () => import('../views/professional/type2.vue')
      },
      {
        path: '/type3',
        name: 'type3',
        meta: { title: '问卷症状' },
        component: () => import('../views/professional/type3.vue')
      },
      {
        path: '/type4',
        name: 'type4',
        meta: { title: '问卷症状' },
        component: () => import('../views/professional/type4.vue')
      },
      // TODO：风险评估
      {
        path: '/evaluationList',
        name: 'evaluationList',
        meta: { title: '评估列表' },
        component: () => import('../views/assessment/evaluationList.vue')
      },
      {
        path: '/psychological',
        name: 'psychological',
        meta: { title: 'SCL-90' },
        component: () => import('../views/assessment/psychological.vue')
      },
      {
        path: '/evaluationRecord',
        name: 'evaluationRecord',
        meta: { title: '测评记录' },
        component: () => import('../views/assessment/evaluationRecord.vue')
      },
      {
        path: '/evaluations',
        name: 'evaluations',
        meta: { title: '评估记录' },
        component: () => import('../views/assessment/evaluations.vue')
      },
      {
        path: '/customerManagement',
        name: 'customerManagement',
        meta: { title: '客户管理' },
        component: () => import('../views/healthFile/customerManagement.vue')
      },
      {
        path: '/myClient',
        name: 'myClient',
        meta: { title: '我的客户' },
        component: () => import('../views/healthFile/myClient.vue')
      },
      {
        path: '/evaluationManage',
        name: 'evaluationManage',
        meta: { title: '评估列表' },
        component: () => import('../views/assessment/evaluationManage.vue')
      },
    ]
  },
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // sessionStorage.setItem('to', JSON.stringify(to))
//   console.log('from :>> ', from);
  NProgress.start()
  next()
})
router.afterEach(() => {
  NProgress.done()
})
export default router
