// mixins.ts
import { Vue, Component } from 'vue-property-decorator';
@Component({})
export class GetPermissions extends Vue {
  public permissions = {}
  public created() {
    const permissions = sessionStorage.getItem('permissions')
    const data = permissions ? JSON.parse(permissions) : []
    data.forEach((ele: string) => {
      (this.permissions as any)[ele] = ele
    })
  }
}