import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAMap from 'vue-amap';
import { GetPermissions } from '@/mixins/getPermissions';
import "@/utils/fullScreen"
Vue.mixin(GetPermissions);
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'c6c5e3abec94ebfd092465a158dd1b53',//目前是濠哥的,如果后期更改你就去https://console.amap.com/dev/key/app
  plugin: ['AMap.Geocoder', 'AMap.Geolocation', 'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});
import {
  Autocomplete,
  Avatar,
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Menu,
  MenuItem,
  MenuItemGroup,
  Select,
  Submenu,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Form,
  FormItem,
  Option,
  Pagination,
  Col,
  DatePicker,
  TimePicker,
  Dialog,
  Tooltip,
  Tag,
  Row,
  Drawer,
  Tree,
  Radio,
  RadioGroup,
  RadioButton,
  Upload,
  Image,
  Switch,
  Checkbox,
  ButtonGroup,
  Popover,
  Collapse,
  CollapseItem,
  Divider,
  InputNumber,
  Loading,
  Popconfirm,
  Transfer
} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import echarts from 'echarts'
import { Message } from 'element-ui';
import { MessageBox } from 'element-ui';
import { Notification } from 'element-ui';
import VueQuillEditor from 'vue-quill-editor'
// import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import changNull from '@/components/crud/crud';
import VueCropper from 'vue-cropper'
Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'small', zIndex: 3000 };
Vue.prototype.$echarts = echarts
Vue.use(Button).use(Select).use(Autocomplete).use(Input).use(Badge).use(Avatar).use(Dropdown)
  .use(DropdownItem).use(DropdownMenu).use(Menu).use(Submenu).use(MenuItem).use(MenuItemGroup)
  .use(TabPane).use(Tabs).use(Form).use(FormItem).use(Option).use(Table).use(TableColumn).use(Pagination)
  .use(Col).use(DatePicker).use(TimePicker).use(Dialog).use(Tooltip).use(Tag).use(Row).use(Drawer)
  .use(Tree).use(Dialog).use(Radio).use(RadioGroup).use(RadioButton).use(Upload).use(Image).use(Switch)
  .use(Checkbox).use(ButtonGroup).use(Popover).use(Collapse).use(CollapseItem).use(Divider).use(InputNumber).use(Loading).use(Popconfirm).use(Transfer)
Vue.use(VueQuillEditor)
Vue.use(VueCropper)
Vue.prototype.$message = Message
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$notify = Notification
Vue.prototype.$changNull = changNull
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
